<template>
    <div>
        <nav-admin></nav-admin>
        <div class="container">
            <div class="row mt-4">
                <div class="col-12 col-md-4">
                    <h2 class="h2 mb-2">Settings</h2>
                     <div v-if="is_success" class="alert alert-success alert-dismissible fade show" role="alert">
                        Changes has been saved!
                        <button v-on:click="is_success = false" type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <b-form autocomplete="off" @submit.prevent="updateSettings">
                        <div v-for="setting in settings" v-bind:key="setting.id">
                            <b-form-group
                                v-if="!isCheckbox(setting.setting_type)"
                                :label="titles[setting.setting_type]"
                                :label-for="'setting'+setting.id">
                                    <b-input
                                    :name="'setting'+setting.id"
                                    v-model="setting.value"
                                    type="text"
                                    ></b-input>
                            </b-form-group>
                            <b-form-checkbox
                                v-if="isCheckbox(setting.setting_type)"
                                v-model="setting.value"
                                :name="'setting'+setting.id"
                                value="1"
                                unchecked-value="0">
                                {{ titles[setting.setting_type] }}
                            </b-form-checkbox>
                        </div>
                        <br>
                        <br>
                        <b-button type="submit" variant="primary">Save</b-button>
                        <router-link class="btn btn-danger" :to="{ path: '/admin' }">Cancel</router-link>
                    </b-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import navAdmin from './../components/nav-admin';
    export default {
        data() {
            return {
                has_error: false,
                is_success: false,
                checkboxes: [6, 7],
                errors:[],
                settings: [],
                titles: [
                    "Instagram refresh token",
                    "Latest synchronization with instagram (UTC)",
                    "Contact form Recipient",
                    "Telegram mentioned member",
                    "Telegram mentioned member (ja)",
                    "",
                    "Sena Kana Status",
                    "Poo Bear Status"
                ]
            }
        },
        mounted() {
            this.getSettings();
        },
        methods: {
            isCheckbox(setting_type) {
                return this.checkboxes.indexOf(+setting_type) >= 0;
            },
            getSettings() {
                this.$http({ url: 'settings', method: 'GET'})
                    .then(
                        (res) => {
                            this.settings = res.data.settings;
                        },
                        () => this.has_error = true
                    );
            },
            updateSettings() {
                this.errors = [];
                for(let i = 0; i < this.settings.length; i++)
                {
                    this.$http({url: 'setting/' + this.settings[i].id, method: "PUT", data: this.settings[i] })
                        .then(() => this.is_success = true)
                        .catch(error => {
                            let str = error.response.request.response;
                            let obj = JSON.parse(str);
                            this.errors.push(obj.errors);
                        });
                }
            }
        },
        components: {
            navAdmin
        }
    }
</script>